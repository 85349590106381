import React from 'react';

import {
  algoliaAnalytics,
  shouldTrackLink,
  useClickIdEnhancedLink,
} from '../../lib/tracking';
import { withDoubleRendering } from './withDoubleRendering';

const ClickIdAwareLink = ({ href, children, ...rest }) => {
  const enhancedHref = useClickIdEnhancedLink(href);

  return (
    <a
      href={enhancedHref}
      {...rest}
      onClick={() => {
        if (shouldTrackLink(href)) {
          try {
            algoliaAnalytics('convertedObjectIDsAfterSearch', {
              userToken: localStorage.getItem('userId'),
              index: 'yso_articles',
              eventName: 'Affiliate Link Clicked',
              queryID: localStorage.getItem('queryID'),
              objectIDs: [localStorage.getItem('objectID')],
            });
          } catch {}
        }
      }}
    >
      {children}
    </a>
  );
};

export default withDoubleRendering(ClickIdAwareLink);
