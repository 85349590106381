export const NORD_PASS_AD_COPY = {
  'how-to-block-inappropriate-videos-on-facebook': [
    {
      id: 'reciprocity',
      header: 'Got the answer you were looking for?',
      body:
        'Great! Now that I showed you how to block videos on Facebook, would you mind checking out my friends at NordPass? They make a great password manager that helps you protect your family.',
      buttonText: 'Check out NordPass',
    },

    {
      id: 'scarcity',
      header: '8.4 billion passwords were leaked in 2021 alone.',
      body:
        "With NordPass, your internet accounts are safe from data breaches. Because it helps you create and remember unique passwords, you won't be hacked when passwords are leaked.",
      buttonText: 'Stay safe with NordPass',
    },

    {
      id: 'authority',
      header: 'Google-level security in your pocket.',
      body:
        'NordPass uses the latest XChaCha20 encryption algorithm. It’s considered the future of encryption, trusted by tech giants from Silicon Valley.',
      buttonText: 'Stay safe with NordPass',
    },

    {
      id: 'consistency',
      header: 'Continue protecting your family.',
      body:
        "You're already doing a great job protecting your family by blocking inappropriate videos on Facebook. Keep the ball rolling by protecting you children's accounts with NordPass.",
      buttonText: 'Check out NordPass',
    },

    {
      id: 'liking',
      header: 'You are a great parent!',
      body:
        "Taking care of your family's safety is not an easy job. Like you, I'm also trying to help people stay safe online by letting them know about NordPass. It's the only password manager your family will need.",
      buttonText: 'Check out NordPass',
    },

    {
      id: 'social-proof',
      header: '22.3 million parents use password managers.',
      body:
        'With a score of 4.7 on Trustpilot, NordVPN is a fan favorite. They offer hassle-free password management and industry-leading security.',
      buttonText: 'Check out NordPass',
    },

    {
      id: 'unity',
      header: 'We are both working hard to keep people safe.',
      body:
        "While I talk about internet safety, you job of being a parent is much more complicated. One of the best tools we have to keep our loved ones safe is NordPass. It's the only password manager your family will need, and the one I'm personally using.",
      buttonText: 'Stay safe with NordPass',
    },
  ],
};
