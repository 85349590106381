import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import RehypeReact from 'rehype-react';

import { BlogContext } from '../../../lib';
import { useClickIdEnhancedLink } from '../../../lib/tracking';
import AffiliateDisclaimer from '../Disclaimer';
import { withDoubleRendering } from '../withDoubleRendering';

const NordStory = ({ isClient }) => {
  const { path } = useContext(BlogContext);

  const { nordStoryAds } = useStaticQuery(graphql`
    query NordStoryQuery {
      nordStoryAds: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "//nord-story/" }
          frontmatter: { weight: { gt: 0 } }
        }
      ) {
        edges {
          node {
            htmlAst
            frontmatter {
              id
              link
              weight
            }
          }
        }
      }
    }
  `);

  const slug = `${path}`.replace('/blog/', '');
  const selectedAdSet = nordStoryAds.edges;
  const selectedAd = selectedAdSet.length
    ? selectedAdSet[Math.floor(Math.random() * selectedAdSet.length)].node
    : undefined;

  const nordQuery = selectedAd
    ? `&aff_id=45264&source=blog_${slug}&aff_sub=${selectedAd.frontmatter.id}`
    : '';
  const link = `${
    selectedAd?.frontmatter.link === 'NordPass'
      ? 'https://go.nordpass.io/aff_c?offer_id=597'
      : 'https://go.nordvpn.net/aff_c?offer_id=15'
  }${nordQuery}`;
  const pixelLink = `${
    selectedAd?.frontmatter.link === 'NordPass'
      ? 'https://go.nordpass.io/aff_i?offer_id=597'
      : 'https://go.nordvpn.net/aff_i?offer_id=15'
  }${nordQuery}`;
  const enhancedHref = useClickIdEnhancedLink(link);

  if (!selectedAd) return null;

  const renderAst = new RehypeReact({
    createElement: React.createElement,
    components: {
      a: withDoubleRendering(({ href, children, ...rest }) => {
        return (
          <a href={enhancedHref} {...rest}>
            {children}
          </a>
        );
      }),
    },
  }).Compiler;

  return (
    <>
      <div> {renderAst(selectedAd.htmlAst)} </div>
      <AffiliateDisclaimer className="p-4" />
      {isClient && (
        <img
          src={pixelLink}
          loading="lazy"
          width="0"
          height="0"
          style={{ position: 'absolute', visibility: 'hidden' }}
          border="0"
        />
      )}
    </>
  );
};

export default withDoubleRendering(NordStory);
