import clsx from 'clsx';
import React from 'react';

const AffiliateDisclaimer = ({ className }) => (
  <>
    <div className={clsx(className, 'bg-primary-100 p-2')}>
      <p className="italic text-sm">
        * By using the above links, you help make the internet a better place.
        If you decide to buy, this web page will be credited with a small
        commission, at NO EXTRA COST to you. Let's make the internet a better
        place, together!
      </p>
    </div>
  </>
);

export default AffiliateDisclaimer;
