import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import { ReactCusdis } from 'react-cusdis';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import RehypeReact from 'rehype-react';

import { Layout, SEO } from '../components';
import BlogPostPreview from '../components/BlogPostPreview';
import ClickIdAwareLink from '../components/rehype/ClickIdAwareLink';
import ClickIdAwarePixel from '../components/rehype/ClickIdAwarePixel';
import AffiliateDisclaimer from '../components/rehype/Disclaimer';
import EyezyAd from '../components/rehype/ads/EyezyAd';
import EzoicAd from '../components/rehype/ads/EzoicAd';
import NordPassAd from '../components/rehype/ads/NordPassAd';
import NordVPNAd from '../components/rehype/ads/NordVPNAd';
import FollowOnFacebook from '../components/rehype/cta/FollowOnFacebook';
import NordPass from '../components/rehype/cta/NordPass';
import NordPassPersonalized from '../components/rehype/cta/NordPassPersonalized';
import NordStory from '../components/rehype/cta/NordStory';
import NordVPN2Y from '../components/rehype/cta/NordVPN2Y';
import PlayQuiz from '../components/rehype/cta/PlayQuiz';
import { BlogContext, formatTimeUntilNow, withEndSlash } from '../lib';

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    'ad-pointer': EzoicAd,
    'affilitate-nordvpn-2y': NordVPN2Y,
    'affilitate-nordpass': NordPass,
    'affilitate-nordpass-personalized': NordPassPersonalized,
    'affilitate-nord-story': NordStory,
    'affilitate-disclaimer': AffiliateDisclaimer,
    'follow-on-facebook': FollowOnFacebook,
    'play-quiz': PlayQuiz,
    a: ClickIdAwareLink,
    img: ClickIdAwarePixel,
    'affilitate-nordvpn-ad': NordVPNAd,
    'affilitate-nordpass-ad': NordPassAd,
    'affilitate-eyezy-ad': EyezyAd,
  },
}).Compiler;

const BlogPost = ({
  data: {
    blogPost,
    headerImage,
    site: { siteMetadata },
  },
}) => {
  const {
    htmlAst,
    timeToRead,
    path,
    title,
    author,
    publishedDate,
    modifiedDate,
    image,
    metaDescription,
    relatedPosts,
  } = blogPost;
  const fullPath = withEndSlash(`${siteMetadata.siteUrl}/${path.substring(1)}`);

  return (
    <Layout bodyClass="standard-layout page-blog-post bg-primary">
      <SEO
        title={title}
        description={metaDescription}
        url={path}
        image={{
          src: image.childImageSharp.fixed.src,
          width: image.childImageSharp.fixed.width,
          height: image.childImageSharp.fixed.height,
        }}
        blogPost={{
          description: metaDescription,
          author: author.frontmatter.name,
          datePublished: publishedDate,
          dateModified: modifiedDate,
        }}
      />

      <BackgroundImage
        Tag="section"
        className="header-section bg-black"
        fluid={[
          'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))',
          headerImage.childImageSharp.fluid,
        ]}
      >
        <div className="container">
          <h1 className="title">{title}</h1>
          <p>
            {timeToRead} min read by{' '}
            <Link to={withEndSlash(author.frontmatter.path)}>
              {author.frontmatter.name}
            </Link>
          </p>
          <p className="text-sm">
            published {formatTimeUntilNow(publishedDate)}, updated{' '}
            {formatTimeUntilNow(modifiedDate)}
          </p>
        </div>
      </BackgroundImage>

      <div className="flex justify-center gap-4 relative">
        <div className="container bg-white">
          <section className="body-section bread-crumbs">
            <p>
              <Link to="/">Home</Link>
              {' > '}
              <Link to="/blog/">Blog</Link>
              {' > '}
              {title}
            </p>
          </section>

          <section className="body-section">
            <BlogContext.Provider
              value={{
                path,
              }}
            >
              <div className="markdown-body">{renderAst(htmlAst)}</div>
            </BlogContext.Provider>
          </section>

          <section className="body-section mt-5">
            <h4>Share this with your friends</h4>
            <div className="flex flex-row row-wrap gap-4 p-4">
              <div className="">
                <FacebookShareButton url={fullPath} quote={title} className="">
                  <FacebookIcon size={32} round />
                </FacebookShareButton>

                <div>
                  <FacebookShareCount url={fullPath} className="">
                    {(count) => count}
                  </FacebookShareCount>
                </div>
              </div>

              <div className="">
                <TwitterShareButton url={fullPath} title={title} className="">
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </div>

              <div className="">
                <TelegramShareButton url={fullPath} title={title} className="">
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
              </div>

              <div className="">
                <WhatsappShareButton
                  url={fullPath}
                  title={title}
                  separator=":: "
                  className=""
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>

              <div className="">
                <RedditShareButton
                  url={fullPath}
                  title={title}
                  windowWidth={660}
                  windowHeight={460}
                  className=""
                >
                  <RedditIcon size={32} round />
                </RedditShareButton>

                <div>
                  <RedditShareCount url={fullPath} className="" />
                </div>
              </div>

              <div className="">
                <EmailShareButton
                  url={fullPath}
                  subject={title}
                  body="body"
                  className=""
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </div>

              <div className="">
                <ViberShareButton url={fullPath} title={title} className="">
                  <ViberIcon size={32} round />
                </ViberShareButton>
              </div>
            </div>
          </section>

          <section className="body-section mt-16">
            <h2>Related Articles</h2>
            <p>Here are some articles you might be interested in:</p>
            <div className="flex flex-row flex-wrap justify-between">
              {relatedPosts &&
                relatedPosts.map((post) => (
                  <BlogPostPreview
                    key={post.path}
                    className="max-2"
                    node={post}
                    displayAuthor
                  />
                ))}
            </div>
          </section>

          <section className="body-section w-full">
            <h2>What questions do you have?</h2>
            <p>I make sure to answer them as soon as possible!</p>
            <ReactCusdis
              attrs={{
                host: 'https://cusdis.com',
                appId: process.env.GATSBY_CUSDIS_APP_ID,
                pageId: path,
                pageTitle: title,
                pageUrl: `${process.env.SITE_URL}${path}`,
              }}
            />
          </section>
        </div>

        <div className="hidden lg:block bg-white sticky top-4 p-4 mb-4 h-0 ad">
          <div
            className="w-80"
            dangerouslySetInnerHTML={{
              __html: `
            <!-- Ezoic - sidebar_floating_1 - sidebar_floating_1 -->
                <div id="ezoic-pub-ad-placeholder-116"> </div>
            <!-- End Ezoic - sidebar_floating_1 - sidebar_floating_1 -->
          `,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    blogPost(id: { eq: $id }) {
      timeToRead
      htmlAst
      title
      path
      metaDescription
      author {
        frontmatter {
          path
          name
        }
      }
      relatedPosts {
        excerpt
        timeToRead
        title
        path
        publishedDate
        author {
          frontmatter {
            name
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      image {
        childImageSharp {
          fixed(width: 1200, height: 630) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      publishedDate
      modifiedDate
    }

    headerImage: file(
      relativePath: { eq: "blog/hero-superman_P9I1HDM3VW.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default BlogPost;
