import React, { useEffect, useState } from 'react';

export const withDoubleRendering = (WrappedComponent) => ({
  children,
  ...rest
}) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, [setIsClient]);

  return (
    <WrappedComponent {...rest} key={isClient} isClient={isClient}>
      {children}
    </WrappedComponent>
  );
};
