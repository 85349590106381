import React from 'react';

import { withDoubleRendering } from '../withDoubleRendering';

const EzoicAd = ({ idx }) => {
  const type =
    idx <= 1
      ? 'under_first_paragraph'
      : idx <= 2
      ? 'under_second_paragraph'
      : idx <= 5
      ? 'mid_content'
      : idx <= 8
      ? 'long_content'
      : idx === 11
      ? 'longer_content'
      : 'under_second_paragraph';

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
  <!-- Ezoic - in_content_${idx} - ${type} -->
    <div id="ezoic-pub-ad-placeholder-1${String(idx).padStart(2, '0')}"> </div>
  <!-- End Ezoic - in_content_${idx} - ${type} -->
  `,
      }}
    />
  );
};

export default withDoubleRendering(EzoicAd);
