import clsx from 'clsx';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image/index';
import React from 'react';

import {
  algoliaAnalytics,
  useClickIdEnhancedLink,
} from '../../../lib/tracking';
import AffiliateDisclaimer from '../Disclaimer';
import { withDoubleRendering } from '../withDoubleRendering';

const NordVPN2Y = ({ className, link, title, subtitle, button }) => {
  const enhancedHref = useClickIdEnhancedLink(link);

  function tracking(event) {
    event.preventDefault();
    try {
      algoliaAnalytics('convertedObjectIDsAfterSearch', {
        userToken: localStorage.getItem('userId'),
        index: 'yso_articles',
        eventName: 'Affiliate Link Clicked',
        queryID: localStorage.getItem('queryID'),
        objectIDs: [localStorage.getItem('objectID')],
      });
    } catch {}
    window.location = enhancedHref;
  }

  return (
    <StaticQuery
      query={imageQuery}
      render={({ ctaImage }) => (
        <>
          <div
            className={clsx(
              'flex flex-col md:flex-row bg-primary-100 p-4',
              className
            )}
          >
            <div className="flex-1 flex flex-col md:mr-4">
              <h4>{title}</h4>
              <p className="flex-1">{subtitle}</p>
              {button && (
                <a
                  href={enhancedHref}
                  className="btn btn-secondary mb-4 mx-auto"
                  onClick={tracking}
                >
                  {button}
                </a>
              )}
              <AffiliateDisclaimer className="hidden md:block" />
            </div>
            <a
              href={enhancedHref}
              className="max-w-xs flex-1"
              onClick={tracking}
            >
              <Img
                alt="NordVPN 70% off deal"
                objectFit="contain"
                fluid={ctaImage.childImageSharp.fluid}
              />
            </a>
          </div>
          <AffiliateDisclaimer className="md:hidden p-4" />
        </>
      )}
    />
  );
};

export default withDoubleRendering(NordVPN2Y);

const imageQuery = graphql`
  query NordVPN2YQuery {
    ctaImage: file(relativePath: { eq: "cta/1200x1200-GDN-v1.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
