import React from 'react';

const FollowOnFacebook = ({ title, subtitle }) => (
  <>
    <div className="flex flex-col md:flex-row bg-primary-100 p-4 items-center">
      <div className="flex-1 flex flex-col md:mr-4">
        <h4>{title}</h4>
        <p className="flex-1">{subtitle}</p>
      </div>
      <iframe
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fyouaresafe.online&tabs&width=340&height=180&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true"
        className="-mx-4 -m-4 my-6 sm:m-0"
        width="340"
        height="180"
        style={{ border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameBorder="0"
        allowTransparency
        allow="encrypted-media"
      />
    </div>
  </>
);

export default FollowOnFacebook;
