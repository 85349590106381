import clsx from 'clsx';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image/index';
import React, { useContext } from 'react';

import { NORD_PASS_AD_COPY } from '../../../data/nordPassAd';
import { BlogContext } from '../../../lib';
import { useClickIdEnhancedLink } from '../../../lib/tracking';
import AffiliateDisclaimer from '../Disclaimer';
import { withDoubleRendering } from '../withDoubleRendering';

const NordPassPersonalized = ({ className, isClient }) => {
  const { path } = useContext(BlogContext);
  const slug = `${path}`.replace('/blog/', '');
  const selectedAdSet = NORD_PASS_AD_COPY[slug];
  const selectedAd = selectedAdSet
    ? selectedAdSet[Math.floor(Math.random() * selectedAdSet.length)]
    : undefined;

  const nordPassQuery = selectedAd
    ? `offer_id=597&aff_id=45264&source=blog_${slug}&aff_sub=${selectedAd.id}`
    : '';
  const link = `https://go.nordpass.io/aff_c?${nordPassQuery}`;
  const pixelLink = `https://go.nordpass.io/aff_i?${nordPassQuery}`;
  const enhancedHref = useClickIdEnhancedLink(link);

  if (!selectedAd) return null;

  const title = selectedAd.header;
  const body = selectedAd.body;
  const button = selectedAd.buttonText;

  return (
    <StaticQuery
      query={imageQuery}
      render={({ ctaImage }) => (
        <>
          <div
            className={clsx(
              'flex flex-col md:flex-row bg-primary-100 p-4',
              className
            )}
          >
            <div className="flex-1 flex flex-col md:mr-4">
              <h4>{title}</h4>
              <p className="flex-1">{body}</p>
              <a href={enhancedHref} className="btn btn-secondary mb-4 mx-auto">
                {button}
              </a>
              <AffiliateDisclaimer className="hidden md:block" />
            </div>
            <a href={enhancedHref} className="max-w-xs flex-1">
              <Img
                alt="NordPass logo"
                objectFit="contain"
                fluid={ctaImage.childImageSharp.fluid}
              />
            </a>
          </div>
          <AffiliateDisclaimer className="md:hidden p-4" />
          {isClient && (
            <img
              src={pixelLink}
              loading="lazy"
              width="0"
              height="0"
              style={{ position: 'absolute', visibility: 'hidden' }}
              border="0"
            />
          )}
        </>
      )}
    />
  );
};

export default withDoubleRendering(NordPassPersonalized);

const imageQuery = graphql`
  query NordPassPersonalizedQuery {
    ctaImage: file(relativePath: { eq: "cta/NP-logo-c-v.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
