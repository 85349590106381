import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useContext } from 'react';

import { BlogContext } from '../../../lib';
import { useClickIdEnhancedLink } from '../../../lib/tracking';
import { withDoubleRendering } from '../withDoubleRendering';

const EyezyAd = ({ isClient }) => {
  const { path } = useContext(BlogContext);

  const { ad } = useStaticQuery(graphql`
    query EyezyAdQuery {
      ad: file(relativePath: { eq: "ads/eyezy/PM_320x480_en.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const slug = `${path}`.replace('/blog/', '').replace('/', '');

  const eyezyQuery = `offer_id=85&aff_id=13683&source=blog_${slug}`;
  const link = `https://track.bzfrs.co/aff_c?${eyezyQuery}`;
  const pixelLink = `https://track.bzfrs.co/aff_i?${eyezyQuery}`;
  const enhancedHref = useClickIdEnhancedLink(link);

  return (
    <>
      <div className="w-full flex items-center justify-center p-4">
        <a href={enhancedHref} className="w-full max-w-lg" target="_blank">
          <Img
            className={clsx(
              'cursor-pointer rounded',
              'transition-all duration-300 ease-out',
              'shadow-xl lg:shadow hover:shadow-2xl',
              'transform lg:scale-95 hover:scale-100'
            )}
            alt="eyezy deal"
            fluid={ad.childImageSharp.fluid}
          />
        </a>
      </div>
      {isClient && (
        <img
          src={pixelLink}
          loading="lazy"
          width="0"
          height="0"
          style={{ position: 'absolute', visibility: 'hidden' }}
          border="0"
        />
      )}
    </>
  );
};

export default withDoubleRendering(EyezyAd);
