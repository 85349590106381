import { navigate } from 'gatsby';
import React from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { animated, useSpring } from 'react-spring';

import logo from '../../../../static/logo-small.svg';
import getFirebase from '../../../lib/firebase';
import { formatInteger } from '../../../lib/spring';

const PlayQuiz = ({
  title = 'Help your kids stay safe online by playing our quiz',
  subtitle = "A few minutes a day will teach you what 'computer words' mean and what you should look out for.",
}) => {
  const [globalResult, globalResultLoading, globalResultError] = useDocument(
    getFirebase()?.firestore().doc('results/cybersecurity-global')
  );
  const animatedNumbers = useSpring({
    points: globalResult?.data()?.totalPoints ?? 0,
    people: globalResult?.data()?.totalPeople ?? 0,
  });

  return (
    <>
      <div className="flex flex-col md:flex-row bg-primary-100 items-center">
        <div className="flex-1 flex flex-col p-4 md:mr-4">
          <h4>{title}</h4>
          <p className="flex-1">{subtitle}</p>
        </div>
        <div className="flex-1 flex flex-col p-4 bg-secondary-200 items-center w-full">
          {!globalResultLoading && !globalResultError && (
            <div className="text-center text-white mb-4">
              We already helped{' '}
              <span className="font-bold whitespace-nowrap">
                <animated.span>
                  {animatedNumbers.people.interpolate(formatInteger)}
                </animated.span>{' '}
                people
              </span>{' '}
              to better understand internet safety.
            </div>
          )}

          <div className="text-center text-white mb-4">
            Let's make the world a safer place, together!
          </div>

          <button
            className="flex flex-col items-center btn btn-accent btn-large text-center w-4/5 mb-4"
            onClick={() => navigate('/cybersecurity-live-quiz')}
          >
            <div className="flex flex-row items-center">
              <img
                className="h-8 mr-8"
                alt="YOU are safe online Homepage"
                src={logo}
              />
              Play now
            </div>
          </button>

          <div className="text-sm text-white mb-4">
            * completely free, no registration, no download
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayQuiz;
