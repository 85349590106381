import React from 'react';

import { useClickIdEnhancedLink } from '../../lib/tracking';
import { withDoubleRendering } from './withDoubleRendering';

const ClickIdAwarePixel = ({ src, children, ...rest }) => {
  const enhancedSrc = useClickIdEnhancedLink(src);

  return (
    <img src={enhancedSrc} {...rest}>
      {children}
    </img>
  );
};

export default withDoubleRendering(ClickIdAwarePixel);
